import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const CCSlider = ({ data }) => {
  return (
    <StyledSlider>
      <div className="bg" />
      <div className="title">
        <div className={font.rSB + " titleTxt"}>SORRISOS</div>
        <div className="titleLine" />
      </div>
      <div className="slider">
        <div className="desc-container">
          <div className="desc">
            <p className={font.rSB + " descTitle"}>
              {data.title}
              <span className="underline"></span>
            </p>
            <p className={font.rM + " descTxt"}>{data.desc}</p>
            <div className={font.rSB + " descBtn"}>
              <Link to="/sorrisos#videos" state={{ bypassCC: true }}>
                VER TESTEMUNHO
              </Link>
            </div>
          </div>
        </div>
        <div className="imagem">
          {data.video ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video playsInline controls poster={data.img.publicURL}>
              <source src={data.video.publicURL} type="video/mp4" />
            </video>
          ) : (
            <Img
              fluid={data.img.childImageSharp.fluid}
              style={{ width: `100%`, height: "100%" }}
              imgStyle={{ objectFit: "cover" }}
            />
          )}
        </div>
      </div>
    </StyledSlider>
  )
}

export default CCSlider

const StyledSlider = styled.div`
  position: relative;
  padding: 13vw 0 0;
  margin: 8vw 0;
  .title {
    position: absolute;
    left: 18%;
    top: 15%;
    font-size: calc(22px + 10 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      font-size: 32px;
    }
    letter-spacing: 0.1em;
    display: flex;
    align-items: baseline;
    width: 82%;
    .titleLine {
      flex-grow: 1;
      height: 2px;
      margin-left: 20px;
      background-color: #b7a99a;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 7.5%;
    width: 85%;
    height: 100%;
    background-color: #eeeae6;
  }
  .slider {
    position: relative;
    width: 85%;
    margin: 0 7.5%;
    display: flex;
    .imagem {
      width: 50%;
      position: relative;
      padding-bottom: 28.125%; // 16:9 / 50% aka 2

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .desc-container {
      width: 50%;
      position: relative;
      padding-bottom: 28.125%; // 16:9 / 50% aka 2
    }
    .desc {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #b7a99a;
      padding: 0 calc(26px + 84 * (100vw - 769px) / 1151);
      padding-right: calc(26px + 24 * (100vw - 769px) / 1151);
      @media only screen and(min-width: 1920px) {
        padding: 0 110px;
        padding-right: 50px;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      .descTitle {
        color: #ffffff;
        font-size: calc(18px + 10 * (100vw - 768px) / 1152);
        @media only screen and(min-width: 1920px) {
          font-size: 28px;
        }
        letter-spacing: 0.15em;
        line-height: 1.3em;
        position: relative;
        margin-bottom: 1.5em;
        .underline {
          width: 2em;
          height: 0.25em;
          background-color: #ffffff;
          position: absolute;
          top: 1.6em;
          left: 0;
        }
      }
      .descTxt {
        color: #ffffff;
        font-size: calc(14px + 8 * (100vw - 768px) / 1152);
        @media only screen and(min-width: 1920px) {
          font-size: 22px;
        }
        letter-spacing: 0.1em;
        line-height: 1.6em;
        margin-bottom: 1.3em;
      }
      .descBtn {
        color: #ffffff;
        border: 1px solid #ffffff;
        font-size: calc(12px + 4 * (100vw - 768px) / 1152);
        @media only screen and(min-width: 1920px) {
          font-size: 16px;
        }
        letter-spacing: 0.1em;
        width: fit-content;
        padding: 5px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        user-select: none;
        cursor: pointer;

        transition: all 0.2s;
      }
      .descBtn:hover {
        background-color: #ffffff;
        color: #b7a99a;
        border-color: #ffffff;
        letter-spacing: 0.25em;
      }
    }
  }
`
