import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Slider from "react-slick"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import seta from "../../images/homepage/seta.svg"

const Banner = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
  }
  
  return(
    <StyledBanner bg={data.bg}>
      <div className="bannerContent">
        <Slider {...settings}>
          {data.imgs.map((data, i) => (
            <div className="bannerImg" key={"equipa-slide" + i}>
              <Img
                fluid={data.img.childImageSharp.fluid}
                style={{ width: `100%`, height: "100%" }}
                imgStyle={{ objectFit: "cover" }}
                alt={data.alt && data.alt}
              />
              <p className={font.rSB + " legenda"}>{data.legenda}</p>
            </div>
          ))}
        </Slider>
        <div className="bannerBtn">
          <Link to="/sobre" className={font.rSB + " linkBtn"}>
            <span className="symbolSize">
              <img src={seta} alt="seta" />
            </span>{" "}
            <span style={{ fontSize: "1.5vw", margin: "0 1em" }}>|</span> SABER
            MAIS
          </Link>
        </div>
      </div>
      <div className="bg" />
    </StyledBanner>
  )
}

export default Banner

const StyledBanner = styled.div`
  position: relative;
  margin: 10vw 0;
  width: 100%;
  padding-bottom: 7vw;
  .bannerContent{
    position: relative;
    background-color: transparent;
    width: 100%;
    .bannerImg{
      position: relative;
      width: calc(248px + 72 * (100vw - 310px) / (768 - 310));
      padding-top: calc(60px + -60 * (100vw - 310px) / (768 - 310));
      z-index: 1;
    }
  }
  .bannerBtn{
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    color: #ffffff;
    background-color: #b7a99a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2vw;
    font-size: 10px;
    letter-spacing: 2px;
    height: 2em;
    .symbolSize{
      height: 1.3em;
      margin-right: 0.5em;
      img{
        width: 7px;
      }
    }
  }
  .bg{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 85%;
    width: 100%;
    background-image: url(${props => (props.bg && props.bg.publicURL)});
    background-size: cover;
    background: #eeeae6;
    z-Index: -1;
  }
  .slick-slider {
    line-height: 0;
  }
  .slick-track {
    display: flex;
  }
  .slick-list {
    overflow: hidden;
  }
`