import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Slider from "react-slick"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import seta from "../../images/homepage/seta.svg"

const Banner = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
  }

  return (
    <StyledBanner bg={data.bg}>
      <div className="bannerContent">
        <Slider {...settings}>
          {data.imgs.map((data, i) => (
            <div className="bannerImg" key={"equipa-slide" + i}>
              <Img
                fluid={data.img.childImageSharp.fluid}
                imgStyle={{ objectFit: "cover" }}
                alt={data.alt && data.alt}
                className="img"
              />
              <p className={font.rSB + " legenda"}>{data.legenda}</p>
            </div>
          ))}
        </Slider>
        <div className="bannerBtn">
          <Link to="/sobre" className={font.rSB + " linkBtn"}>
            <span className="symbolSize">
              <img src={seta} alt="seta" />
            </span>{" "}
            <span className="more">|</span> SABER
            MAIS
          </Link>
        </div>
      </div>
      <div className="bg" />
    </StyledBanner>
  )
}

export default Banner

const StyledBanner = styled.div`
  position: relative;
  margin: 10vw 0 0;
  width: 100%;
  padding-bottom: 7vw;
  .bannerContent {
    position: relative;
    background-color: black;
    width: 85%;
    .bannerImg {
      height: 35vw;
      width: 35vw;
      position: relative;

      .img {
        width: 100%; 
        height: 100%;
      }

    }
  }
  .bannerBtn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);

    .more {
      font-size: 1.5vw;
      margin: 0 1em;
    }
  }
  .linkBtn {
    color: #ffffff;
    background-color: #b7a99a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    font-size: calc(12px + 4 * (100vw - 768px) / 1152);
    letter-spacing: 0.1em;
  }
  .symbolSize {
    height: 1em;
    img {
      width: 0.6em;
    }
  }
  .bg {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 85%;
    width: 100%;
    background: #eeeae6;
    z-index: -1;
  }
  .legenda {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(183, 169, 154, 0.6);
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.1em;
    padding: 20px 20px;
  }
  .slick-slider {
    line-height: 0;
  }
  .slick-track {
    display: flex;
  }
  .slick-list {
    overflow: hidden;
  }
`
