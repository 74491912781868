import React from "react"
import Div100vh from "react-div-100vh"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"

import paixao from "../../images/homepage/paixao.svg"
import playBtn from "../../images/homepage/btnPlay.svg"

const HomeScreen = ({ data }) => {
  function play() {
    // Get the video
    var video = document.querySelector("#myVideo")

    var fade = document.querySelectorAll(".fade")
    var notFade = document.querySelector(".notFade")
    var play = document.querySelector("#btnPlay")
    var i

    // Pause and play the video
    if (video.paused) {
      video.play()
      for (i = 0; i < fade.length; i++) {
        fade[i].style.opacity = "0"
      }
      notFade.style.opacity = "1"
      notFade.style.pointerEvents = "unset"
      play.style.left = "140%"
      play.style.top = "225%"
    } else {
      video.pause()
      for (i = 0; i < fade.length; i++) {
        fade[i].style.opacity = "1"
      }
      notFade.style.opacity = "0"
      notFade.style.pointerEvents = "none"
      play.style.left = "33%"
      play.style.top = "40%"
    }
  }

  return (
    <StyledScreen>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        loop
        playsInline
        crossOrigin="anonymous"
        id="myVideo"
        poster={data.posterMobile.publicURL}
      >
        <source src={data.videoLow.publicURL} type="video/mp4" />
      </video>

      <div id="myBtn" className="fade">
        <p className={font.rM + " fade"}>
          A MEDICINA É UMA ARTE
          <br />
          QUE SE FAZ COM
        </p>
        <img src={paixao} alt="Paixão" className="homeBtnPaixao" />
        <Button styleless onClick={play} className="homeBtnPlay">
          <img src={playBtn} alt="Play" id="btnPlay" />
        </Button>
        <p className={font.rSB + " homeBtnVeja fade"}>VEJA O VÍDEO</p>
      </div>
      <Button styleless className={font.rSB + " notFade"} onClick={play}>
        PARAR O VÍDEO
      </Button>
    </StyledScreen>
  )
}

export default HomeScreen

const StyledScreen = styled(Div100vh)`
  width: 100%;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  height: 80vh !important;
  #homeVideoBack {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: -4;
  }
  #myVideo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    /*width: 177.77777778vh;  100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
    z-index: -5;
    object-fit: cover;
  }
  #myBtn {
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: 10%;
    font-size: 1.5em;
    color: #939598;
    letter-spacing: 0.18em;
    font-weight: 500;
    transform: translateX(-50%);
  }
  .homeBtnPaixao {
    width: 7em;
    margin-top: -0.4em;
  }
  .homeBtnPlay {
    margin: auto;
    transition: opacity 0.3s, left 0.6s ease-in 0.1s, top 0.6s ease-out;
    z-index: 5;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    cursor: pointer;
    img {
      width: 100px;
    }
    :hover {
      opacity: 0.5;
    }
  }
  .homeBtnVeja {
    font-size: 0.7em;
  }
  .fade,
  .notFade {
    transition: opacity, letter-spacing 0.3s;
  }
  .notFade {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    width: fit-content;
    letter-spacing: 2px;
    :hover {
      letter-spacing: 4px;
    }
    pointer-events: none;
    opacity: 0;
    background-color: rgba(227, 227, 227, 0.3);
    padding: 5px 10px;
  }
`