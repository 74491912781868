import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import ServicoCardMobile from "../cards/servicoMobile"

import seta from "../../images/homepage/seta.svg"

const Tratamentos = ({ data }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true
  };
  
  return(
    <StyledTratamentos>
      <div className="contentBoard">
      <Slider {...settings}>
        {data.map((data, i)=>(
          <ServicoCardMobile data={data} key={"tratamento" + i}/>
        ))}
      </Slider>
      </div>
      <div className="lineBottom">
        <div className="lineBot"/>
        <Link to="/servicos" className={font.rSB + " link"}><span className="symbolSize"><img src={seta} alt="seta"/></span> <span style={{fontSize: "1.5vw", marginRight: "0.5em"}}>|</span> VER TODOS</Link>
      </div>
    </StyledTratamentos>
  )
}

export default Tratamentos

const StyledTratamentos = styled.div`
  position: relative;
  margin: 8vw 0;
  width: 100%;
  .contentBoard{
    position: relative;
    margin: auto;
    overflow: hidden;
    .slick-track{
      display: flex;
    }
  }
  .lineBottom{
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    .lineBot{
      background-color: #b7a99a;
      height: 2px;
      width: 55%;
    }
  }
  .link{
    color: #ffffff;
    background-color: #b7a99a;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 1vw 3vw;
    font-size: 10px;
    letter-spacing: 2px;
  }
  .symbolSize{
    margin-right: 0.5em;
    height: 1.3em;
    img{
      width: 7px;
    }
  }
`