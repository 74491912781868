import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const ServicoCard = ({ data }) => {
  
  return(
    <StyledTratamentos as={Link} to={data.link}>
      <div className="tratamentoImg">
        <Img fluid={data.img.childImageSharp.fluid} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "cover" }} alt={data.alt && data.alt}/>
      </div>
      <div className="contentBg"/>
      <div className="content">
        <p className={font.rSB + " contentTitle"}>{data.nome}<span className="smallUnderline"></span></p>
        {data.detalhes.map((data, i)=>(
          <p className={font.rM + " detalhes"} key={"tratamentoDetalhe" + i}>{data}</p>
        ))}
      </div>
    </StyledTratamentos>
  )
}

export default ServicoCard

const StyledTratamentos = styled.div`
  position: relative;
  height: 100vw;
  width: 70vw;
  margin: auto;
  .tratamentoImg{
    height: 60%;
    width: 80%;
  }
  .contentBg{
    height: 40%;
    background-color: #eeeae6;
  }
  .content{
    position: absolute;
    background: #fff;
    bottom: 8%;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    padding: 6% 8%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .contentTitle{
      color: #231f20;
      font-size: calc(16px + 12 * (100vw - 320px) / 448);
      letter-spacing: 0.1em;
      position: relative;
    }
    .smallUnderline{
      width: 2em;
      height: 0.25em;
      background-color: #b7a99a;
      position: absolute;
      bottom: -7px;
      left: 0;
    }
    .detalhes{
      color: #808080;
      font-size: calc(12px + 10 * (100vw - 320px) / 448);
      line-height: 1.6em;
      letter-spacing: 0.1em;
    }
  }
`