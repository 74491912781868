import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const ServicoCard = ({ data }) => {
  
  return(
    <StyledTratamentos as={Link} to={data.link}>
      <div className="tratamentoImg">
        <Img fluid={data.img.childImageSharp.fluid} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "cover" }} alt={data.alt && data.alt}/>
      </div>
      <div className="contentBg"/>
      <div className="content">
        <p className={font.rSB + " contentTitle"}>{data.nome}</p>
        <div className="smallUnderline"/>
        {data.detalhes.map((data, i)=>(
          <p className={font.rM + " detalhes"} key={"tratamentoDetalhe" + i}>{data}</p>
        ))}
      </div>
    </StyledTratamentos>
  )
}

export default ServicoCard

const StyledTratamentos = styled.div`
  position: relative;
  height: 35vw;
  .tratamentoImg{
    height: 60%;
    width: 80%;
  }
  .contentBg{
    height: 40%;
    background-color: #eeeae6;
  }
  .content{
    position: absolute;
    background: #fff;
    bottom: 3vw;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    padding: calc(8px + 30 * (100vw - 768px) / 1152);
    height: 50%;
    .contentTitle{
      color: #231f20;
      font-size: calc(12px + 14 * (100vw - 768px) / 1152);
      line-height: 2em;
      letter-spacing: 0.1em;
      position: relative;
    }
    .smallUnderline{
      width: 2em;
      height: 0.25em;
      background-color: #b7a99a;
      position: relative;
    }
    .detalhes{
      color: #808080;
      font-size: calc(12px + 10 * (100vw - 768px) / 1152);
      letter-spacing: 0.1em;
      line-height: 1.6em;
      margin: 1vw 0;
    }
  }
`