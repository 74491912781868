import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Destaque = ({ data }) => {

  return (
    <>
      <StyledSpotlight>
        <div className="slider">
          <div className="slides">
            <Img
              fluid={data.img.childImageSharp.fluid}
              style={{ width: `100%`, height: "100%" }}
              imgStyle={{ objectFit: "cover" }}
              alt={data.alt && data.alt}
            />
          </div>
          <div className="lineLeft" />
        </div>
        <div className="title">
          <p className={font.rB + " title1"}>IMPLANTOLOGIA</p>
          <p className={font.rB + " title2"}></p>
        </div>
        <div className="content">
          <div className="contentTxtContainer">
            <p className={font.rSB + " subtitle"}>
              <span className="smallUnderline"></span>
              IMPLANTOLOGIA
            </p>
            <p className={font.rM + " contentTxt"}>
              Reabilitamos sorrisos através da colocação de implantes dentários.
              As nossas clínicas dentárias estão equipadas com CBCT, uma tecnologia de
              vanguarda que permite avaliar, com maior qualidade e rigor,
              diagnósticos e patologias. Para além disso, é possível definir o
              planeamento de cirurgias de implantologia graças ao seu software
              digital.
            </p>
          </div>
        </div>
        <div className="lineRight" />
        <Link to="/servicos/implantologia_av" className="link">
          <p className={font.rSB + " verticalTxt hLTSaber"}>
            <span className="mydot"></span>SABER MAIS
          </p>
        </Link>
      </StyledSpotlight>
      <StyledSpotlight>
        <div className="slider">
          <div className="slides">
            <Img
              fluid={data.imgsecond.childImageSharp.fluid}
              style={{ width: `100%`, height: "100%" }}
              imgStyle={{ objectFit: "cover" }}
              alt={data.altsecond && data.altsecond}
            />
          </div>
          <div className="lineLeft" />
        </div>
        <div className="title">
          <p className={font.rB + " title1"}>HARMONIZAÇÃO</p>
          <p className={font.rB + " title2"}>FACIAL</p>
        </div>
        <div className="content">
          <div className="contentTxtContainer">
            <p className={font.rSB + " subtitle"}>
              <span className="smallUnderline"></span>
              HARMONIZAÇÃO FACIAL
            </p>
            <p className={font.rM + " contentTxt"}>
              A harmonização facial é um conjunto de procedimentos estéticos
              e funcionais que criam um equilíbrio entre o seu rosto e o sorriso.
              Estes procedimentos proporcionam o alinhamento e a correção de
              alguns ângulos do rosto, prevenindo o envelhecimento facial e
              realçando a beleza natural. É necessário haver uma primeira consulta
              de diagnóstico onde o especialista define os melhores procedimentos
              a realizar.
            </p>
          </div>
        </div>
        <div className="lineRight" />
        <Link to="/servicos/harmonizacao-facial" className="link">
          <p className={font.rSB + " verticalTxt hLTSaber"}>
            <span className="mydot"></span>SABER MAIS
          </p>
        </Link>
      </StyledSpotlight>
    </>
  )
}

export default Destaque

const StyledSpotlight = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: calc(465px + 435 * (100vw - 768px) / 1152);
  @media only screen and(min-width: 1920px) {
    min-height: 900px;
  }
  .slider {
    position: relative;
    padding-top: calc(60px + 90 * (100vw - 769px) / 1151);
    @media only screen and(min-width: 1920px) {
      padding-top: 150px;
    }
    width: 40%;
  }
  .slides {
    position: relative;
    height: 40vw;
  }
  .lineLeft {
    position: absolute;
    top: 0;
    left: 55px;
    height: 100%;
    width: 2px;
    background-color: #707070;
  }
  .sliderBtns {
    position: absolute;
    left: 55px;
    bottom: 10%;
    opacity: 0; // Remover os botões por agora
  }
  .sliderBtn {
    width: 4em;
    height: 4em;
    background-color: #b7a99a;
    color: #ffffff;
    text-align: center;
    line-height: 5.3em;
    z-index: 1;
    position: relative;
    top: 80%;
    left: 3%;
    margin-bottom: 0.2%;
    margin-top: 3px;

    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    cursor: pointer;
  }
  .sliderBtn img {
    height: 50%;
  }
  .title {
    width: 100%;
    position: absolute;
    top: 10vw;
    left: 50%;
    transform: translateX(-50%);
    font-size: calc(46px + 69 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      font-size: 115px;
    }
  }
  .title1 {
    letter-spacing: 0.2em;
    margin-left: 10vw;
    color: #ffffff;
  }
  .title2 {
    color: #b7a99a;
    letter-spacing: 0.2em;
    text-align: right;
    margin-right: 10vw;
  }
  .content {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #eeeae6;
    width: 75vw;
    height: calc(465px + 435 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      height: 900px;
    }
    z-index: -1;
  }
  .contentTxtContainer {
    position: absolute;
    top: calc(188px + 352 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      top: 540px;
    }
    left: 20vw;
    width: 52vw;
    .subtitle {
      color: #231f20;
      font-size: calc(18px + 8 * (100vw - 768px) / 1152);
      @media only screen and(min-width: 1920px) {
        font-size: 26px;
      }
      line-height: 2em;
      position: relative;
    }
    .smallUnderline {
      width: 2em;
      height: 0.25em;
      background-color: #b7a99a;
      position: absolute;
      top: 2em;
      left: 0;
    }
    .contentTxt {
      color: #808080;
      font-size: calc(14px + 8 * (100vw - 768px) / 1152);
      @media only screen and(min-width: 1920px) {
        font-size: 22px;
      }
      line-height: 2em;
      margin-top: 1em;
    }
  }

  .lineRight {
    position: absolute;
    top: 0;
    right: 54px;
    height: calc(30px + 275 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      height: 305px;
    }
    width: 2px;
    background-color: #707070;
  }
  .verticalTxt {
    writing-mode: vertical-lr;
    transform: translateX(50%) rotate(-180deg);
    margin: 0.5% 0 0.5% 3%;
    position: absolute;
    right: 54px;
    top: calc(30px + 275 * (100vw - 768px) / 1152);
    font-size: calc(13px + 3 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      top: 305px;
      font-size: 16px;
    }
    color: #231f20;
    letter-spacing: 0.2em;
  }
  .mydot {
    background: #b7a99a;
    border-radius: 50%;
    height: 1em;
    width: 1em;
    position: absolute;
    bottom: 110%;
    left: 0.1em;
  }
`
