import React from "react"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import ServicoCard from "../cards/servico"

import seta from "../../images/homepage/seta.svg"

const Tratamentos = ({ data }) => {
  
  return(
    <StyledTratamentos>
      <div className="title">
        <div className={font.rSB + " titleTxt"}>SERVIÇOS</div><div className="lineTop"/>
      </div>
      <div className="contentBoard">
        {data.map((data, i)=>(
          <ServicoCard data={data} key={"tratamento" + i}/>
        ))}
      </div>
      <div className="lineBottom">
        <div className="lineBot"/>
        <Link to="/servicos" className={font.rSB + " link"}><span className="symbolSize"><img src={seta} alt="seta"/></span> <span style={{fontSize: "1.5vw", margin: "0 1em"}}>|</span> VER TODOS</Link>
      </div>
    </StyledTratamentos>
  )
}

export default Tratamentos

const StyledTratamentos = styled.div`
  position: relative;
  margin: 8vw 0;
  .title{
    display: flex;
    margin-left: 25%;
    margin-bottom: 3vw;
    width: 75%;
    align-items: baseline;
  }
  .titleTxt{
    font-size: calc(20px + 12 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px){
      font-size: 32px;
    }
    letter-spacing: 0.1em;
    color: #231f20;
  }
  .lineTop{
    background-color: #b7a99a;
    height: 2px;
    flex-grow: 1;
    margin-left: 20px;
  }

  .contentBoard{
    position: relative;
    width: 85%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 4vw;
  }
  .lineBottom{
    margin-top: 3vw;
    width: 100%;
    display: flex;
    align-items: center;
    .lineBot{
      background-color: #b7a99a;
      height: 2px;
      width: 65%;
    }
  }
  .link{
    color: #ffffff;
    background-color: #b7a99a;
    height: 2.5vw;
    width: calc(200px + 50 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px){
      width: 250px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9em 1.2em;
    font-size: 16px;
    letter-spacing: 3.2px;
    .symbolSize{
      height: 1em;
      img{
        width: 0.6em;
      }
    }
  }
`