import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const CCSlider = ({ data }) => {
  return (
    <StyledSlider>
      <div className="bg" />
      <div className="title">
        <div className={font.rSB + " titleTxt"}>SORRISOS</div>
        <div className="titleLine" />
      </div>
      <div className="slider">
        <div className="slide">
          <div className="antes">
            {data.video ? (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video playsInline controls poster={data.img.publicURL}>
                <source src={data.video.publicURL} type="video/mp4" />
              </video>
            ) : (
              <Img
                fluid={data.img.childImageSharp.fluid}
                style={{ width: `100%`, height: "100%" }}
                imgStyle={{ objectFit: "cover" }}
              />
            )}
          </div>

          <div className="desc">
            <p className={font.rSB + " descTitle"}>
              {data.title}
              <span className="underline"></span>
            </p>
            <p className={font.rM + " descTxt"}>{data.desc}</p>
            <div className={font.rSB + " descBtn"}>
              <Link to="/sorrisos#videos" state={{ bypassCC: true }}>
                VER TESTEMUNHO
              </Link>
            </div>
          </div>
        </div>
      </div>
    </StyledSlider>
  )
}

export default CCSlider

const StyledSlider = styled.div`
  position: relative;
  margin: 8vw 0;
  .title {
    font-size: 20px;
    letter-spacing: 0.1em;
    display: flex;
    align-items: baseline;
    width: 90%;
    margin-left: 10%;
    padding: calc(25px + 25 * (100vw - 320px) / 448) 0
      calc(25px + 25 * (100vw - 320px) / 448) 8%;
    color: #231f20;
    position: relative;
    background-color: #eeeae6;
    .titleLine {
      flex-grow: 1;
      height: 2px;
      margin-left: 20px;
      background-color: #b7a99a;
    }
  }
  .slider {
    position: relative;
    width: 90%;
    margin-left: 10%;
    .slide {
      height: 100%;
      width: 100%;
      .antes {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;

        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .desc {
        position: relative;
        width: 100%;
        background-color: #b7a99a;
        height: 100%;
        padding: calc(22px + 48 * (100vw - 320px) / 448);
        .descTitle {
          color: #ffffff;
          font-size: calc(18px + 18 * (100vw - 320px) / 448);
          letter-spacing: 0.1em;
          line-height: 1.3em;
          position: relative;
          margin-bottom: 1.5em;
          .underline {
            width: 2em;
            height: 0.25em;
            background-color: #ffffff;
            position: absolute;
            top: 1.6em;
            left: 0;
          }
        }
        .descTxt {
          color: #ffffff;
          font-size: calc(14px + 6 * (100vw - 320px) / 448);
          letter-spacing: 0.12em;
          line-height: 1.6em;
        }
        .descBtn {
          padding: 20px 40px;
          margin-top: calc(20px + 30 * (100vw - 320px) / 448);
          color: #ffffff;
          border: solid #ffffff;
          font-size: calc(14px + 6 * (100vw - 320px) / 448);
          letter-spacing: 0.15em;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          -webkit-touch-callout: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          user-select: none;
          cursor: pointer;

          transition: all 0.2s;
        }
        .descBtn:hover {
          background-color: #ffffff;
          color: #b7a99a;
          border-color: #ffffff;
          letter-spacing: 0.25em;
        }
      }
    }
  }
`
