import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Destaque = ({ data }) => {

  return (
    <>
      <StyledSpotlight>
        <p className={font.rSB + " subtitle"}>
          <span className="smallUnderline"></span>
          IMPLANTOLOGIA
        </p>
        <div className="slider">
          <div className="slides">
            <Img
              fluid={data.img.childImageSharp.fluid}
              style={{ width: `100%`, height: "100%" }}
              imgStyle={{ objectFit: "cover" }}
              alt={data.alt && data.alt}
            />
          </div>
          <div className="lineLeft" />
        </div>
        <p className={font.rM + " contentTxt"}>
          Reabilitamos sorrisos através da colocação de implantes dentários. As
          nossas clínicas estão equipadas com CBCT, uma tecnologia de vanguarda
          que permite avaliar, com maior qualidade e rigor, diagnósticos e
          patologias. Para além disso, é possível definir o planeamento de
          cirurgias de implantologia graças ao seu software digital.
        </p>
        <Link
          to="/servicos/implantologia_av"
          className={font.rSB + " verticalTxt hLTSaber"}
        >
          <span className="mydot" />
          SABER MAIS
          <span className="myline" />
        </Link>
      </StyledSpotlight>
      <StyledSpotlight>
        <p className={font.rSB + " subtitle"}>
          <span className="smallUnderline"></span>
          HARMONIZAÇÃO FACIAL
        </p>
        <div className="slider">
          <div className="slides">
            <Img
              fluid={data.imgsecond.childImageSharp.fluid}
              style={{ width: `100%`, height: "100%" }}
              imgStyle={{ objectFit: "cover" }}
              alt={data.altsecond && data.altsecond}
            />
          </div>
          <div className="lineLeft" />
        </div>
        <p className={font.rM + " contentTxt"}>
          A harmonização facial é um conjunto de procedimentos estéticos
          e funcionais que criam um equilíbrio entre o seu rosto e o sorriso.
          Estes procedimentos proporcionam o alinhamento e a correção de
          alguns ângulos do rosto, prevenindo o envelhecimento facial e
          realçando a beleza natural. É necessário haver uma primeira consulta
          de diagnóstico onde o especialista define os melhores procedimentos
          a realizar.
        </p>
        <Link
          to="/servicos/harmonizacao-facial"
          className={font.rSB + " verticalTxt hLTSaber"}
        >
          <span className="mydot" />
          SABER MAIS
          <span className="myline" />
        </Link>
      </StyledSpotlight>
    </>
  )
}

export default Destaque

const StyledSpotlight = styled.div`
  position: relative;
  width: 100%;
  background-color: #eeeae6;
  padding: 50px 0;
  .subtitle {
    color: #231f20;
    font-size: calc(20px + 12 * (100vw - 320px) / 448);
    line-height: 1.2em;
    letter-spacing: 0.1em;
    position: relative;
    margin: 0 35px 2em;
  }
  .smallUnderline {
    width: 2em;
    height: 0.25em;
    background-color: #b7a99a;
    position: absolute;
    bottom: -0.4em;
    left: 0;
  }
  .slider {
    position: relative;
    width: 90%;
    height: 50vh;
  }
  .slides {
    position: relative;
    height: 100%;
  }
  .lineLeft {
    position: absolute;
    top: 0;
    left: 35px;
    height: 100%;
    width: 2px;
    background-color: #707070;
  }
  .sliderBtns {
    position: absolute;
    left: 35px;
    bottom: 10%;
    display: none; // Remover botões para já.
  }
  .sliderBtn {
    width: 4em;
    height: 4em;
    background-color: rgba(183, 169, 154, 0.5);
    color: #ffffff;
    text-align: center;
    line-height: 5.3em;
    z-index: 1;
    position: relative;
    top: 80%;
    left: 3%;
    margin-bottom: 0.2%;
    margin-top: 3px;

    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    cursor: pointer;
  }
  .sliderBtn img {
    height: 50%;
  }
  .contentTxt {
    color: #808080;
    font-size: calc(14px + 5 * (100vw - 320px) / 448);
    line-height: 1.6em;
    letter-spacing: 0.1em;
    padding: 35px;
  }
  .verticalTxt {
    color: #231f20;
    font-size: calc(12px + 4 * (100vw - 320px) / 448);
    letter-spacing: 0.2em;
    display: flex;
    align-items: center;
  }
  .mydot {
    margin: 0 12px 0 35px;
    background: #b7a99a;
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
  .myline {
    margin-left: 12px;
    background: #b7a99a;
    height: 2px;
    flex-grow: 1;
  }
`
