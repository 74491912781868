import React, { useEffect, useRef } from "react"
import Div100vh from "react-div-100vh"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"

import paixao from "../../images/homepage/paixao.svg"
import playBtn from "../../images/homepage/btnPlay.svg"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const HomeScreen = ({ data, ...props }) => {
  function play() {
    // Get the video
    var video = document.querySelector("#myVideo")

    var fade = document.querySelectorAll(".fade")
    var notFade = document.querySelector(".notFade")
    var play = document.querySelector("#btnPlay")
    var i

    // Pause and play the video
    if (video.paused) {
      video.play()
      for (i = 0; i < fade.length; i++) {
        fade[i].style.opacity = "0"
      }
      notFade.style.opacity = "1"
      notFade.style.pointerEvents = "unset"
      play.style.left = "140%"
      play.style.top = "225%"
    } else {
      video.pause()
      for (i = 0; i < fade.length; i++) {
        fade[i].style.opacity = "1"
      }
      notFade.style.opacity = "0"
      notFade.style.pointerEvents = "none"
      play.style.left = "33%"
      play.style.top = "40%"
    }
  }

  const animTrigger = useRef(),
    animVideo = useRef(),
    animLeftLine = useRef(),
    animRightLine = useRef(),
    animBottomLine = useRef(),
    animButtonLine1 = useRef(),
    animButtonLine2 = useRef(),
    animButtonLine3 = useRef(),
    animButton = useRef(),
    animButtonSubtitle = useRef(),
    animDrawer1 = useRef(),
    animDrawer2 = useRef(),
    animDrawer3 = useRef()

  useEffect(() => {
    props.isAnimated &&
      gsap
        .timeline({
          paused: true,
          scrollTrigger: {
            trigger: animVideo.current,
            start: "top top",
          },
        })
        .fromTo(
          [
            animButtonLine1.current,
            animButtonLine2.current,
            animButtonLine3.current,
            animButton.current,
            animButtonSubtitle.current,
          ],
          { opacity: 0, y: 15 },
          { opacity: 1, y: 0, duration: 2, stagger: 0.25, ease: "power4.out" }
        )
        .fromTo(
          [animDrawer1.current, animDrawer2.current, animDrawer3.current],
          { opacity: 0, scaleY: 0.85, transformOrigin: "bottom 50%" },
          {
            opacity: 1,
            scaleY: 1,
            duration: 1,
            stagger: 0.25,
            ease: "power2.inOut",
          },
          ">-2"
        )
        .fromTo(
          [animLeftLine.current, animRightLine.current, animBottomLine.current],
          { opacity: 0 },
          { opacity: 1, duration: 3 },
          ">"
        )
        .fromTo(
          animVideo.current,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 2.5,
            ease: "power3.inOut",
          },
          "0.25"
        )
  }, [props.isAnimated])

  function openCloseDrawer(d) {
    let drawer = document.querySelector("." + d).style.height
    if (drawer === "0px" || drawer === "") {
      document.querySelector("." + d).style.height =
        "calc(260px + 90 * (100vw - 769px) / 1151)"
    } else {
      document.querySelector("." + d).style.height = "0px"
    }
  }

  return (
    <StyledScreen ref={animTrigger}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={animVideo}
        loop
        playsInline
        crossOrigin="anonymous"
        id="myVideo"
        poster={data.poster.publicURL}
        preload="none"
      >
        <source src={data.videoLow.publicURL} type="video/mp4" />
      </video>

      <div className="leftLines" ref={animLeftLine}>
        <div className="leftTop fade" />
        <a
          href="https://www.instagram.com/carreiradentalclinic/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <p className={font.rSB + " verticalTxt fade"}>INSTAGRAM</p>
        </a>
        <div className="leftMiddle fade" />
        <a
          href="https://www.facebook.com/carreiradentalclinic"
          rel="noopener noreferrer"
          target="_blank"
        >
          <p className={font.rSB + " verticalTxt fade"}>FACEBOOK</p>
        </a>
        <div className="leftBottom fade" />
      </div>

      <div className="bottomLines" ref={animBottomLine}>
        <div className="bottomLeft fade" />
        <p className={font.rSB + " horizontalTxt fade"}>AVEIRO</p>
        <div className="bottomMiddle fade" />
        <p className={font.rSB + " horizontalTxt fade"}>ÁGUEDA</p>
        <div className="bottomMiddle fade" />
        <p className={font.rSB + " horizontalTxt fade"}>PORTO</p>
        <div className="bottomMiddle fade" />
        <p className={font.rSB + " horizontalTxt fade"}>VISEU</p>
        <div className="bottomMiddle fade" />
        <p className={font.rSB + " horizontalTxt fade"}>OLI. AZEMÉIS</p>
        <div className="bottomRight fade" />
      </div>

      <div className="rightLines" ref={animRightLine}>
        <div className="rightTop fade" />
        <p className={font.rSB + " verticalTxt fade"}>É BOM SORRIR</p>
        <div className="rightBottom fade" />
      </div>

      <div id="myBtn" className="fade">
        <div className={`${font.rM} fade`}>
          <span ref={animButtonLine1}>A MEDICINA É UMA ARTE</span>
          <br />
          <span ref={animButtonLine2}>QUE SE FAZ COM</span>
        </div>
        <img
          ref={animButtonLine3}
          src={paixao}
          alt="Paixão"
          className="homeBtnPaixao"
        />
        <Button
          ref={animButton}
          styleless
          onClick={play}
          className="homeBtnPlay"
        >
          <img src={playBtn} alt="Play" id="btnPlay" />
        </Button>
        <p ref={animButtonSubtitle} className={font.rSB + " homeBtnVeja fade"}>
          VEJA O VÍDEO
        </p>
      </div>

      <div className="homeDrawers fade">
        <div className={font.rSB + " homeDrwr"} ref={animDrawer1}>
          <Button
            className={font.rSB + " homeDrwrTitle"}
            onClick={() => {
              openCloseDrawer("hDC1")
            }}
          >
            HORÁRIO
          </Button>
          <div className="homeDrwrContent hDC1">
            <div className="block">
              <p>
                Clínicas:
                <br /> Porto | Aveiro | Águeda | Viseu | Oli. Azeméis <br />
                <br />
              </p>
              <p>seg-sex: 9h-13h | 14h-20h</p>
              <p>sábado: 9h-13h | 14h-19h</p>
            </div>
          </div>
        </div>
        <div className={font.rSB + " homeDrwr"} ref={animDrawer2}>
          <Button
            className={font.rSB + " homeDrwrTitle"}
            onClick={() => {
              openCloseDrawer("hDC2")
            }}
          >
            MARCAÇÕES
          </Button>
          <div className="homeDrwrContent hDC2">
            {/* <div className="block">
              <a href="mailto:clinica@carreiradentalclinic.pt">clinica@carreiradentalclinic.pt</a>
            </div> */}
            <Link
              className="consulta firstMargin"
              id="consultaBtn"
              to="/contactos"
            >
              CONTACTOS
            </Link>
            <Link
              className="consulta"
              id="consultaBtn"
              to="/pedido-de-marcacao"
            >
              MARCAR CONSULTA
            </Link>
          </div>
        </div>
        <div className={font.rSB + " homeDrwr"} ref={animDrawer3}>
          <Button
            className={font.rSB + " homeDrwrTitle"}
            onClick={() => {
              openCloseDrawer("hDC3")
            }}
          >
            SERVIÇOS
          </Button>
          <div className={font.rSB + " homeDrwrContent hDC3"}>
            <Link to="/servicos/implantologia_av">Implantologia</Link>
            <Link to="/servicos/cirurgia_oral">Cirurgia Oral</Link>
            <Link to="/servicos/ortodontia">Ortodontia</Link>
            <Link to="/servicos/odontopediatria">Odontopediatria</Link>
            <Link
              className="consulta firstMargin"
              id="consultaBtn"
              to="/servicos"
            >
              VER TODOS
            </Link>
          </div>
        </div>
      </div>
      <Button styleless className={font.rSB + " notFade"} onClick={play}>
        PARAR O VÍDEO
      </Button>
    </StyledScreen>
  )
}

export default HomeScreen

const StyledScreen = styled(Div100vh)`
  width: 100%;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  background-color: #000000;
  .leftLines {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      width: 50px;
    }
    .leftTop {
      height: 30%;
      background-color: #707070;
      width: 2px;
    }
    .leftMiddle {
      height: 20px;
      background-color: #707070;
      width: 2px;
    }
    .leftBottom {
      flex-grow: 1;
      background-color: #707070;
      width: 2px;
    }
  }
  .rightLines {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .rightTop {
      height: 35%;
      background-color: #707070;
      width: 2px;
    }
    .rightBottom {
      flex-grow: 1;
      background-color: #707070;
      width: 2px;
    }
  }
  .bottomLines {
    position: absolute;
    height: 30%;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    .bottomLeft {
      height: 2px;
      background-color: #707070;
      width: 30%;
    }
    .bottomMiddle {
      width: 20px;
      background-color: #707070;
      height: 2px;
    }
    .bottomRight {
      flex-grow: 1;
      background-color: #707070;
      height: 2px;
    }
  }
  .verticalTxt {
    font-size: 0.875em;
    writing-mode: vertical-lr;
    color: #ffffff;
    transform: rotate(-180deg);
    margin: 15px auto;
  }
  .horizontalTxt {
    margin: 0 15px;
    font-size: 0.9em;
    font-weight: 600;
    color: #ffffff;
  }
  /* ----------------------------------------------------------------------------------------------------------- */
  .homeDrawers {
    color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    padding-left: calc(0px + 380 * (100vw - 768px) / 1152);
    padding-right: calc(0px + 380 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      padding-left: 380px;
      padding-right: 380px;
    }
    font-size: 1.5em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1em;
    align-items: flex-end;
  }
  .homeDrwr {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
  }
  .homeDrwrTitle {
    width: 100%;
    height: 70px;
    text-align: center;
    font-size: calc(16px + 1 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      font-size: 17px;
    }
    line-height: 1.2em;
    letter-spacing: 0.1em;
    background-color: #b7a99a;
    cursor: pointer;
  }
  .homeDrwrContent {
    text-align: center;
    padding: 0 23px;
    background-color: rgba(183, 169, 154, 0.9);
    height: 0;
    overflow: hidden;
    line-height: 30px;
    font-size: calc(12px + 4 * (100vw - 768px) / 1152);
    @media only screen and(min-width: 1920px) {
      font-size: 16px;
    }
    letter-spacing: 1.6px;
    transition: height 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 350px;
    > * {
      margin: 0.2em 0;
    }
    .firstMargin {
      margin: 2em 0 0.5em;
    }
  }
  .block {
  }
  .consulta {
    background-color: #b7a99a;
    padding: 5px 20px;
  }
  .hDC3 {
    text-transform: uppercase;
    text-align: left;
  }
  #homeVideoBack {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: -4;
  }
  #myVideo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
    z-index: 0;
    object-fit: cover;
  }
  #myBtn {
    text-align: center;
    position: absolute;
    right: 18%;
    bottom: 50%;
    font-size: 1.5em;
    color: #939598;
    letter-spacing: 0.18em;
    font-weight: 500;
    line-height: 0.65;
  }
  #myBtn span {
    display: block;
  }
  .homeBtnPaixao {
    width: 7em;
    margin-top: -0.2em;
  }
  .homeBtnPlay {
    opacity: 0;
    margin: auto;
    transition: opacity 0.3s, left 0.6s ease-in 0.1s, top 0.6s ease-out;
    z-index: 5;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    cursor: pointer;
    img {
      width: 100px;
    }
    :hover {
      opacity: 0.5;
    }
  }
  .homeBtnVeja {
    opacity: 0;
    font-size: 0.7em;
  }
  .fade,
  .notFade {
    transition: opacity 0.4s;
  }
  .notFade {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    width: fit-content;
    letter-spacing: 2px;
    :hover {
      letter-spacing: 4px;
    }
    pointer-events: none;
    opacity: 0;
    background-color: rgba(227, 227, 227, 0.3);
    padding: 5px 10px;
  }
`